import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';

import { videoEditorStore } from 'modules/video-editor-module';
import { routeKeyToMessageId } from 'modules/routing-module';

import { editorProjectsStore } from 'stores/editor-projects';

import { formatSeconds, toast } from 'utils';
import { EditorResponse } from 'utils/api/api';

import { Confirmation, Layout } from 'components/common';
import { ITableProps, Table } from 'components/table';

import './styles.scss';
import IconEditor from 'assets/icons/editor-gray.svg';
import IconPlaylistDelete from 'assets/icons/playlist-delete.svg';

const tableColumns: ITableProps['columns'] = [
	'id',
	{ accessor: 'name', withSort: true },
	{ accessor: 'totalDuration', withSort: true },
	{ accessor: 'renderingStatus', withSort: true },
	{ accessor: 'createdAt', withSort: true },
	{ accessor: 'updatedAt', withSort: true },
	'actions',
];

const EditorProjectsComponent: FC = () => {
	const [project, setProject] = useState<null | EditorResponse>(null);
	const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);

	const intl = useIntl();

	useEffect(() => {
		void editorProjectsStore.getList();
	}, [editorProjectsStore.order]);

	const handleDeleteClick = useCallback((p: EditorResponse) => {
		setProject(p);
		setConfirmationIsOpen(true);
	}, []);

	const renderConfirmation = () => {
		if (!confirmationIsOpen || !project) {
			return null;
		}

		const message = intl.formatMessage(
			{
				id: 'Deleting Confirm',
			},
			{
				it: project.name,
			},
		);

		const handleConfirm = () => {
			if (!project) {
				return;
			}

			videoEditorStore.deleteProject(project.id).then(() => {
				setConfirmationIsOpen(false);

				toast.success(
					intl.formatMessage(
						{ id: 'Project Removed' },
						{ projectName: project.name },
					),
				);

				editorProjectsStore.getList();
			});
		};

		const handleCancel = () => {
			setConfirmationIsOpen(false);
		};

		return (
			<Confirmation
				messageId={message}
				onConfirm={handleConfirm}
				onCancel={handleCancel}
			/>
		);
	};

	const tableData = useMemo(
		() =>
			editorProjectsStore.list.map((p) => ({
				...p,
				renderingStatus: (
					<FormattedMessage
						id={p.renderingStatus}
						defaultMessage={p.renderingStatus}
					/>
				),
				createdAt: intl.formatDate(p.createdAt),
				updatedAt: intl.formatDate(p.updatedAt),
				totalDuration: formatSeconds(p.totalDuration),
				actions: (
					<Fragment key={`table_row${p.id}_action`}>
						<Link
							to={`/editor/project/${p.id}`}
							title={intl.formatMessage({
								id: routeKeyToMessageId('Editor'),
							})}
						>
							<img
								src={IconEditor}
								alt="Open"
								className="table__icon table__icon__action"
							/>
						</Link>
						<button onClick={() => handleDeleteClick(p)}>
							<img
								src={IconPlaylistDelete}
								alt="Delete"
								className="table__icon table__icon__action"
							/>
						</button>
					</Fragment>
				),
			})),
		[editorProjectsStore.list, handleDeleteClick, intl],
	);

	return (
		<Layout>
			{renderConfirmation()}
			<h1 className="main__title">
				<FormattedMessage id="Video projects" defaultMessage="Video projects" />
			</h1>
			<Table
				className="editor-list__table scroll"
				columns={tableColumns}
				data={tableData}
				onSortClick={editorProjectsStore.setOrder}
				order={editorProjectsStore.order}
			/>
		</Layout>
	);
};

export const EditorProjects = observer(EditorProjectsComponent);
